<template>
  <!-- <div class="prices-container"> -->
  <div class="insurance-container">
    <h1 class="has-text-primary has-text-weight-bold" :class="displayOnTouch ? 'is-size-huge-mobile' : 'is-size-huge'">Assurance Plaisance </h1>
    <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase">Expertise, garanties, sérénité. </h2>
    <p class="has-text-justified">
      <!-- <em>Le marché du stockage de bateaux est de plus en plus contraignant, saturé, et ce, partout en France.</em> -->
    </p>
    <p>
      <strong class="is-uppercase">Votre devis personnalisé en ligne sur <a href="https://plaisance.axa.fr"> plaisance.axa.fr </a> </strong><br />
      Obtenez un tarif d’assurance pour votre embarcation. <br> 
      C'est simple et rapide !
    </p>
    <p>
      <strong class="is-uppercase has-text-primary">Vos avantages !</strong>
      <br>
      <br/>
          <strong> Profitez de l’expertise AXA </strong>
          <br>
            - 1re marque mondiale d’assurance(1)
            <br>
            - Un des leaders de l’assurance plaisance.
          <br>
            - 1er réseau de distribution avec 3 500 Agents Généraux AXA.
    </p>
    <!-- <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase"><span class="break">JeStockeMonBateau.fr :</span> la garantie de prix justes !</h2> -->
    <p>
      <strong class="is-uppercase ">Maitrisez votre budget</strong><br>
      - Aucune franchise après deux ans sans sinistre(2).
      <br>
    - Jusqu’à 23% de réduction sur le tarif de votre contrat selon la durée de votre désarmement et vos antécédents.
    <br>
    - Vous avez une expérience maritime professionnelle d’au moins 1 an ?Profitez de 10 % de réduction sur le tarif de votre contrat.
    </p>

    <p>
     <strong class="is-uppercase">Naviguez l’esprit libre</strong><br>
     - En cas de sinistre garanti, possibilité de bénéficier d’un bateau de remplacement.<br>
    - Assistance 24h/24 et 7j/7.
    </p>
    
      <!-- <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase"></h2> -->
      <strong class="is-uppercase has-text-primary">Des options qui vous protègent, vous et votre bateau(1)</strong>
      <br>
      <br>
          - Valeur à neuf 5 ans pour les bateaux volés ou détruits(4).
            <br>
          - Assistance au bateau à quai et aux personnes (propriétaires du navire, équipage et passagers à titre gratuit): Avance des frais médicaux à l'étranger et/ou rapatriement.
          Si le bateau est immobilisé, dans l'attente de réparations, organisation et prise en charge: des nuits d'hôtels, de l'envoi des pièces détachées introuvables sur place, du déplacement d'un technicien spécialisé, en cas d'avarie, si non disponible sur place.
          <br> 
          - Protection juridique :
          Accompagnement amiable et judiciaire en cas de litige contre le vendeur, le réparateur ou le prestataire chargé du gardiennage de votre bateau.
          Prise en charge des frais et honoraires engagés.
          <br>
          -Sécurité nautique en cas d’accident de navigation :
          Versement d’un capital au conjoint en cas d’incapacité permanente ou de décès.
          Remboursement des frais médicaux restant à votre charge
      <br><br>
        <i> (1) Selon clauses et conditions du contrat.<br></i>
      (2) Règlement des frais liés au retrait du bateau du fond de l’eau ou à son éventuelle destruction, lorsque l’état ou les autorités compétentes l’imposent.<br>
      (3) Frais liés à la remise à flot du bateau.<br>
      (4) Déduction faite des franchises éventuelles.
    

    <br>
    <br>
    
    <p>
      <strong class="is-uppercase has-text-primary">Des garanties spécifiques (1)</strong>
    </p>
    <p>
      <strong class="is-uppercase"> Pour votre bateau semi rigide </strong><br>
    Votre remorque est inférieure à 750 kg ?

    Elle est garantie en Responsabilité Civile, mais également en cas de dommages et de vol(2).
    </p>
    <p>
      <strong class="is-uppercase"> Pour votre jet-ski </strong><br>
    Vous avez la possibilité de souscrire la Formule 2 sans la garantie vol. Bénéficiez ainsi d’un tarif plus attractif.
    <br>
    <br>
    
    <i> (1) Selon la formule choisie.
      <br>
    (2) Au delà de 750 kg, nécessité de souscrire un contrat séparé</i>
    </p>
    <br>
  <div class="has-text-centered">  
      <figure>
          <img :src="require('./../../assets/icone_AXA_service.png')" alt="JeStockeMonBateau.fr - Axa Service" title="JeStockeMonBateau.fr - Axa Service" class="image is-96x96 .is-horizontal-center">
      </figure>

      Votre espace Client <strong> Mon AXA </strong>
      <br>
      <br>
      <hr class="style_hr">
      <br>
      Retrouvez l'ensemble de vos services en ligne sur <strong> Mon AXA </strong>  via <a href="https://www.axa.fr">axa.fr</a>
      <br>
      <br>

      <strong> AXA </strong> vous répond sur : 
      <br>
      <br>
      <hr class="style_hr particular_hr">
      <br>
      

      <div class="columns is-inline-flex ">
        <div class="column">
          <figure>
            <img :src="require('./../../assets/logo_messenger.png')" alt="JeStockeMonBateau.fr - Messenger Axa" title="JeStockeMonBateau.fr - Axa Service" class="image is-48x48 .is-horizontal-center">
        </figure>
        </div>
        <div class="column">
          <figure>
            <img :src="require('./../../assets/logo_facebook.png')" alt="JeStockeMonBateau.fr - Facebook Axa" title="JeStockeMonBateau.fr - Axa Service" class="image is-48x48 .is-horizontal-center">
        </figure>
        </div>
        <div class="column">
          <figure>
            <img :src="require('./../../assets/logo_twitter.png')" alt="JeStockeMonBateau.fr - Twitter Axa  " title="JeStockeMonBateau.fr - Axa Service" class="image is-48x48 .is-horizontal-center">
        </figure>
        </div>
      </div>

  </div>
  </div>
</template>

<script>
export default {
  name: 'Insurance',
  data () {
    return {
    }
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Insurance' });

    let title = (siteName+' - Assurance - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! C\'est vous qui définissez les tarifs en fonction des besoins que vous soyez bateau-stocker ou bateau-skipper, nous nous occupons du reste !';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">

.style_hr{
  margin:auto;
  width:25%;
  background-color: #0ec0b7;
}
.particular_hr{
  width:13% !important;
}
.is-regularImage {
  transform: scale(1);
  opacity: 1;
}
.is-smallImage {
    transform: scale(0.9);
    opacity: 0.8;
}
figure {
    position:relative
}
.image {
  display:inline;
}
  .insurance-container {
    margin: 5% 30%;
  }
  @media screen and (max-width: 2048px){
    .insurance-container {
      margin: 5% 25%;
    }
  }
  @media screen and (max-width: 1640px){
    .insurance-container {
      margin: 5% 20%;
    }
  }
  @media screen and (max-width: 1365px){
    .insurance-container {
      margin: 5% 15%;
    }
  }
  @media screen and (max-width: 1171px){
    .insurance-container {
      margin: 5% 10%;
    }
  }
  .insurance-container h1{
    padding-bottom: 5%;
  }
  .is-size-huge {
    font-size: 8rem;
    line-height: 8rem;
    hyphens: auto;
  }
  .is-size-huge-mobile{
    font-size: 6rem;
  }
  h2 {
    line-height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .break {
    word-break: break-all
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
  @media screen and (max-width: 1087px) {
    .container {
      margin: 5%;
    }
  }
  @media screen and (max-width: 537px) {
    .container {
      margin: 5%;
    }
    .is-size-huge-mobile{
      font-size: 3rem;
      line-height: 3rem;
    }
  }
</style>
